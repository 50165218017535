<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <!-- <el-button
            type="primary"
            @click="outExeAll('examPersonal')"
            >전체출력</el-button> -->
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
        <template v-slot:subject_info_title="slotProps">
          <div>
            {{ slotProps.callback.row.subject_info.title }}
          </div>
        </template>
        <template v-slot:chapter_info_title="slotProps">
          <div>
            {{ slotProps.callback.row.chapter_info.title }}
          </div>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { personalManagementDetail } from "@/api/index";
export default {
  name: "personalManagementDetail",
  mixins: [mixin_list(personalManagementDetail)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
        id:this.$route.query.id
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("exam.subject_title"),
          sortable: true,
          prop: "subject_info.title",
          slot: "subject_info_title"
        },
        {
          label: this.$t("exam.chapter_title"),
          sortable: true,
          prop: "chapter_info.title",
          slot: "chapter_info_title"
        },
        {
          label: this.$t("exam.solved_rate_detail"),
          sortable: true,
          prop: "solved_rate",
        },
        {
          label: this.$t("exam.correct_rate_detail"),
          sortable: true,
          prop: "correct_rate",
        },
      ],
    };
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.text{
  width:80px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>